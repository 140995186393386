// @ts-strict-ignore
import { Schema } from 'jsonschema'
import { VendiaUniqueKey } from 'src/types/schema'

import { AccessLevel, Acl, Operation, Row, SharingRule, SharingRules } from './csv-uploader'

const getOperationName = ({ prefix, entity }) => {
  return `${prefix}_${entity}`
}

// Generate the graphql input from the row data
const generateGraphqlInputFromRow = ({ row, schema, entity }: { row: Row; schema: Schema; entity: string }): string => {
  const keys = Object.keys(row)
  const values = Object.values(row)
  const graphqlInput = values
    .map((value, index) => {
      const key = keys[index]
      const property = (schema.properties[entity].items as Schema).properties[key]
      const schemaType = property.type

      if (schemaType === 'string') {
        if (property.enum) {
          return `${key}: ${value}`
        }
        return `${key}: "${(value as string)?.trim()}"`
      }
      if (schemaType === 'number' || schemaType === 'integer') {
        return `${key}: ${value}`
      }
      if (schemaType === 'boolean') {
        return `${key}: ${value}`
      }
      throw new Error(`${schemaType} not supported`)
      // TODO: Nested objects and arrays are not supported
    })
    .join(', ')
  return graphqlInput
}

// Generate graphql result from the schema
const generateGraphqlResultFromSchema = ({ schema, entity }: { schema: Schema; entity: string }): string => {
  const keys = Object.keys((schema.properties[entity].items as Schema).properties)
  const resultKeys = keys
    .map((key) => {
      return key
    })
    .join('\n')

  return `
    ... on Self_${entity} {
      _id
      _owner
      _acl {
        operations
        path
        principal {
          nodes
        }
      }
      ${resultKeys}
    }
    `
}

const generateGraphqlKeyParamaterFromSchema = ({
  schema,
  entity,
  row,
}: {
  schema: Schema
  entity: string
  row: Row
}) => {
  const keys = (schema.properties[entity].items as Schema)[VendiaUniqueKey] as string[]
  const firstKey = keys[0]
  const firstKeyValue = row[firstKey]

  return `${firstKey}: "${firstKeyValue}"`
}

const mapOperationToAccessLevel = (operation: Operation): AccessLevel => {
  // Grouping these operations together for simplicity
  if (['GET', 'LIST', 'READ'].includes(operation)) {
    return 'READ'
    // Grouping these operations together for simplicity
  } else if (['UPDATE', 'INVOKE', 'DELETE', 'WRITE'].includes(operation)) {
    return 'WRITE'
  } else if (['ALL'].includes(operation)) {
    return 'READ_WRITE'
  } else if (['UPDATE_ACL'].includes(operation)) {
    return 'ADMIN'
  }
}

export const generateSharingRulesFromAcl = ({ acl }: { acl: Acl[] }): SharingRule[] => {
  // Find all the unique partners in the acl
  const uniquePartnersInAcls = acl.reduce((acc, curr) => {
    const partners = curr.principal.nodes
    partners.forEach((partner) => {
      if (!acc.includes(partner)) {
        acc.push(partner)
      }
    })
    return acc
  }, [])

  const sharingRules: SharingRule[] = uniquePartnersInAcls.map((partner) => {
    const fields = acl
      .filter((acl) => {
        return acl.principal.nodes.includes(partner)
      })
      .map((acl) => {
        return {
          name: acl.path ? acl.path : '*',
          accessLevel: mapOperationToAccessLevel(acl.operations[0]),
        }
      })

    return {
      partner,
      fields,
    }
  })

  return sharingRules
}

export const generateAclsFromSharingRules = ({ sharingRules }: { sharingRules?: SharingRule[] }): Acl[] => {
  if (!sharingRules) {
    return []
  }
  const rulesWithSomeAccess = sharingRules.filter((rule) => {
    return rule.fields.some((field) => field.accessLevel !== 'NO_ACCESS')
  })

  const acls: Acl[] = rulesWithSomeAccess
    .map((rule) => {
      return rule.fields
        .filter((field) => field.accessLevel !== 'NO_ACCESS') // Default is no access, so filter out these fields
        .map((field) => {
          return {
            operations: [field.accessLevel === 'READ_WRITE' ? 'ALL' : (field.accessLevel as Operation)],
            path: field.name === '*' ? undefined : `${field.name}`,
            principal: {
              nodes: [rule.partner],
            },
          }
        })
    })
    .flat()
  return acls
}

export const generateAclInputFromSharingRules = ({ sharingRules }: { sharingRules?: SharingRules }): string => {
  if (!sharingRules) {
    return ''
  }
  const { rules } = sharingRules

  const acls = generateAclsFromSharingRules({ sharingRules: rules })

  const aclsStrings = acls.map((acl) => {
    return `
      {
        principal: {
          nodes: [${acl.principal.nodes.map((node) => `"${node}"`).join(', ')}]
        }, 
        operations: [${acl.operations.map((operation) => `${operation}`).join(', ')}], 
        path: ${acl.path ? `"${acl.path}"` : `""`} 
      }`
  })
  return `{
      acl: [${aclsStrings.join(', \n')}]
    }`
}

export const generateAclInputFromAcl = ({ acl }: { acl?: Acl[] }): string | null => {
  if (!acl) {
    return null
  }
  const aclsStrings = acl.map((acl) => {
    return `
      {
        principal: {
          nodes: [${acl.principal.nodes.map((node) => `"${node}"`).join(', ')}]
        }, 
        operations: [${acl.operations.map((operation) => `${operation}`).join(', ')}], 
        ${acl.path ? `path: "${acl.path}"` : ``} 
      }`
  })
  return `{
      acl: [${aclsStrings.join(', \n')}]
    }`
}

export const generateUpdateOperation = ({
  entity,
  row,
  schema,
  aclInput,
}: {
  entity: string
  row: Row
  schema: Schema
  aclInput?: string
}): string => {
  const keyParam = generateGraphqlKeyParamaterFromSchema({ schema, entity, row })

  return `${getOperationName({
    prefix: 'update',
    entity,
  })}(${keyParam}, ${aclInput ? `aclInput: ${aclInput},` : ``} input: {${generateGraphqlInputFromRow({
    entity,
    row,
    schema,
  })}}, syncMode: NODE_COMMITTED) {
        transaction {
          _id
          _owner
          transactionId
          submissionTime
          version
        }
        result {
          ${generateGraphqlResultFromSchema({ entity, schema })}
        }
      }
    `
}

export const generateAddOperation = ({
  entity,
  row,
  schema,
  aclInput,
}: {
  entity: string
  row: Row
  schema: Schema
  aclInput?: string
}) => {
  return `${getOperationName({ prefix: 'add', entity })}(${
    aclInput ? `aclInput: ${aclInput},` : ``
  } input: {${generateGraphqlInputFromRow({
    entity,
    row,
    schema,
  })}}, syncMode: NODE_COMMITTED) {
        transaction {
          _id
          _owner
          transactionId
          submissionTime
          version
        }
        result {
          ${generateGraphqlResultFromSchema({ entity, schema })}
        }
      }
    `
}
