import { LakehouseSyncScheduleEnum } from '@vendia/management-api-types'
import { useNavigate, useParams } from 'react-router'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import SharingRulesTable from 'src/components/tables/sharing-rules-table'
import { StepId } from 'src/pages/uni-create/config'
import { isRequiredValidator } from 'src/utils/form/validation'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

export const CreateVendiaTable: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const tableNameRef = useFirstFocus()
  const navigate = useNavigate()

  {
    /*TODO: Remove after demo */
  }
  const getUniQuery = useGetUni()
  const uni = getUniQuery.data?.getUni
  const { node } = useParams<{ node: string }>()
  const nodes = uni?.nodes?.filter((n) => n.name !== node)

  return (
    <StepWrapper>
      <StepContentHeader
        hasMarginY
        large
        centered
        title='Configure your new Vendia table'
        description={'A vendia table allows you to configure what data you would like to share'}
      ></StepContentHeader>
      <ScrollableStepContent inset className='pt-8'>
        <div className='flex w-full max-w-4xl flex-col gap-4'>
          <TextField
            name='product.name'
            label='Name'
            placeholder='Enter name'
            description='Name your table'
            ref={tableNameRef}
            validators={lakehouseFlowFieldValidator({
              stepId: StepId.CreateVendiaTable,
              validator: isRequiredValidator(),
            })}
            useNestedLabel
            form={context.form}
          />
          <ListboxField
            name='table.syncSchedule'
            label='Frequency (Refresh rate)'
            description='How often should this table be updated?'
            options={[
              { value: LakehouseSyncScheduleEnum.OnDemand, label: 'On-demand' },
              { value: LakehouseSyncScheduleEnum.Daily, label: 'Daily' },
              { value: LakehouseSyncScheduleEnum.Weekly, label: 'Weekly' },
              { value: LakehouseSyncScheduleEnum.Monthly, label: 'Monthly' },
            ]}
            form={context.form}
            useNestedLabel
          />
          {/*TODO: Remove after demo */}
          <div className='text-neutral-8 -mb-3 text-[12px]'>Table access controls</div>
          {uni && nodes && (
            <div className='rounded-xl shadow'>
              <SharingRulesTable form={context.form} partners={nodes} entityProperties={[]} hideCustom />
            </div>
          )}
        </div>
      </ScrollableStepContent>
      <context.form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isPristine]}>
        {([canSubmit, isSubmitting, isPristine]) => (
          <StepButtons
            context={context}
            hasPrevious={false}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            isSubmitting={isSubmitting}
            nextBlocked={!canSubmit || isPristine}
          />
        )}
      </context.form.Subscribe>
    </StepWrapper>
  )
}
