import { HTMLAttributes, useState } from 'react'
import Card from 'src/components/containers/card'
import { FlowContext } from 'src/components/flows/types'
import { StepId } from 'src/pages/uni-create/config'
import useApi from 'src/utils/hooks/use-api'

import Alert from '../messages/alert'
import ConfirmationModal from '../modals/confirmation-modal'

export type SourceButtonProps = HTMLAttributes<HTMLDivElement> & {
  imageSrc: string
  label: string
  description: string
  message?: string
  linkText?: string
  connection: string
  context: FlowContext<any>
  to: StepId
  disabled?: boolean
  onClick?: () => void
}

export const SourceButton = ({
  imageSrc,
  label,
  description,
  context,
  connection,
  to,
  disabled,
  message,
  linkText,
  onClick,
  ...rest
}: SourceButtonProps) => {
  const form = context.form
  const { nodeName, source, uniName } = form.useStore((s) => s.values)
  const [showSourceModal, setShowSourceModal] = useState<boolean>(false)
  const existingShareAppConfig = form.useStore((s) => s.values.existingConfig)
  const api = useApi()

  const onNextStep = () => {
    if (connection !== 'existing') {
      form.setFieldValue('source.connection', connection)
    }
    setTimeout(() => context.goToStep(to))
  }

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (source.connection && source.connection !== connection) {
      setShowSourceModal(true)
      return
    }
    onNextStep()
  }

  // When the source is changed, we need to clear source fields otherwise the form will be in an invalid state
  const clearSource = () => {
    const sourceKeys = Object.keys(form?.fieldInfo || {}).filter((key) => key.startsWith(`source.${source.connection}`))
    sourceKeys.forEach((sourceKey) => {
      form.deleteField(sourceKey)
    })
    context.resetValidationErrors()
    setShowSourceModal(false)
    onNextStep()
  }

  return (
    <div
      className='flex w-full items-center gap-4 place-self-stretch drop-shadow-md hover:drop-shadow-lg md:w-1/2 lg:w-1/3'
      {...rest}
    >
      <button onClick={onClick || handleClick} className='h-full w-full' disabled={disabled}>
        <Card className='h-full' ribbonMessage={message}>
          <div className='flex flex-col items-center py-4'>
            <div className='relative mb-4 rounded-full border-2 p-1'>
              <img src={imageSrc} alt={label} className='h-24 w-24 rounded-full' />
            </div>
            <h2 className='text-2xl font-bold'>{label}</h2>
            <p className='text-xs text-gray-500'>{description}</p>
            <p className='mt-4 text-xs font-bold text-blue-500 underline'>{linkText}</p>
          </div>
        </Card>
      </button>
      <ConfirmationModal
        isOpen={showSourceModal}
        onClose={() => setShowSourceModal(false)}
        title={`Before you swap to ${label}`}
        onSubmit={() => clearSource()}
        isSubmitting={false}
        actionButtonType={`primary`}
        actionButtonText={`Change source`}
      >
        <div className='grid gap-4'>
          <Alert className='text-sm italic text-gray-500'>
            Note: Changing to {label} will erase any connection information you have entered. Are you sure you want to
            continue?
          </Alert>
        </div>
      </ConfirmationModal>
    </div>
  )
}
