import { RadioGroup } from '@headlessui/react'
import { FieldApi, useField } from '@tanstack/react-form'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import ListboxField from 'src/components/fields/listbox.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import RadioOption from 'src/components/inputs/radio-option'
import { isRequiredValidator } from 'src/utils/form/validation'
import { getColumnOptions } from 'src/utils/lakehouse/flows'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import { StepId } from '../config'

export const StepSelectUpdateColumns: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const navigate = useNavigate()

  const form = context.form
  const { selectedTableColumns, keyColumns } = form.useStore((state) => state.values)
  const columnOptions = getColumnOptions(selectedTableColumns)
  const updateChoices = [
    {
      value: 'columnUpdate',
      label: 'Specify table columns',
      description:
        'You will need to specify both a "last updated" timestamp column and unique row identifier column(s) in order to enable incremental data updates. We will use the timestamp column to keep track of new or updated rows since the previous data sync. The unique identifier column(s) will be used to merge updated row changes into your existing data set in Vendia.',
    },
    {
      value: 'noUpdate',
      label: 'Continue without specifying columns',
      description:
        'In some scenarios incremental updates are either impossible or unnecessary. If your source data set does not have reliable "last updated" timestamp or unique identifier columns, or the data set does not need to be updated frequently, then you can select this option to have the entire data set re-synced from your source system whenever a data sync is requested.',
    },
  ]
  const [selected, setSelected] = useState(updateChoices[0].value)
  const selectionChange = (value: string) => {
    setSelected(value)
    form.setFieldValue('lastUpdatedColumn', '')
    // @ts-ignore - errorMap is not in the types
    form.setFieldMeta('lastUpdatedColumn', {
      ...form.getFieldMeta('lastUpdatedColumn'),
      errorMap: { onChange: undefined },
    })
    form.setFieldValue('lastUpdatedColumnFormat', '')
    form.setFieldValue('keyColumns', [''])
  }
  const keyColumnField = useField<any, any>({
    name: 'keyColumns',
    form,
    mode: 'array',
    defaultValue: keyColumns,
  })
  async function addKeyColumn(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue('')
    }
  }
  const clearKeyColumn = (index: number) => {
    keyColumnField.removeValue(index)
  }

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Set up incremental updates'
        description='Specify unique key and last updated columns to optimize the time and cost of continually syncing data updates from your source system'
      />
      <ScrollableStepContent inset>
        <RadioGroup className='w-full max-w-4xl' value={selected} onChange={selectionChange} name='incrementalChoice'>
          <Card className='min-h-64 w-full max-w-4xl rounded-md' contentExpandVertical>
            <div className='flex w-full flex-col gap-4'>
              <RadioOption key={JSON.stringify(updateChoices[0])} option={updateChoices[0]} />
              <Card onWhiteBg title='Update Columns'>
                <ListboxField
                  name='lastUpdatedColumn'
                  label='Select timestamp column'
                  options={columnOptions}
                  useNestedLabel
                  setWidthAccordingToOptions
                  wrapperClassName='!w-[400px]'
                  className='!w-full'
                  form={form}
                  validators={lakehouseFlowFieldValidator({
                    stepId: StepId.SelectUpdateColumns,
                    validator: isRequiredValidator(null, selected === updateChoices[0].value),
                  })}
                  disabled={selected === updateChoices[1].value}
                />
                {/* 
                TODO - commentting this out for now. First pass is asking the user for date or timestamp columns. There's also column type detection on the backend.
                     - if we really want them to specify formats, that will also require work on the backend to validate their format and make sure it works
                <div className='w-1/2'>
                  <TextField
                    label='Date/Time Format'
                    description='If column is a string, provide the format of the date/time'
                    name='lastUpdatedColumnFormat'
                    form={form}
                    useNestedLabel
                    disabled={selected === updateChoices[1].value}
                  />
                </div> */}
              </Card>
              <Card onWhiteBg title='Key Columns'>
                {keyColumns.map((keyColumn, index) => {
                  const fieldPrefix = `keyColumns.${index}`
                  return (
                    <div className='flex items-center gap-2' key={fieldPrefix}>
                      <ListboxField
                        name={`${fieldPrefix}`}
                        label='Select unique identifier column'
                        options={columnOptions}
                        useNestedLabel
                        setWidthAccordingToOptions
                        wrapperClassName='!w-[400px]'
                        className='!w-full'
                        form={form}
                        disabled={selected === updateChoices[1].value}
                        validators={lakehouseFlowFieldValidator({
                          stepId: StepId.SelectUpdateColumns,
                          validator: isRequiredValidator(null, selected === updateChoices[0].value && index === 0),
                        })}
                      />
                      {index !== 0 && (
                        <div className={'p-2'}>
                          <Button kind='link' icon='trash' onClick={() => clearKeyColumn(index)} />
                        </div>
                      )}
                    </div>
                  )
                })}
                <Button
                  kind={'secondary'}
                  onClick={(e) => {
                    e.preventDefault()
                    addKeyColumn(keyColumnField)
                  }}
                  icon='plus-m'
                  iconSize={14}
                  className='mt-2'
                  disabled={keyColumns.some((keyColumn) => !keyColumn) || selected === updateChoices[1].value}
                >
                  Add another for composite key
                </Button>
              </Card>
            </div>
          </Card>
          <Card className='mt-2 min-h-32 w-full max-w-4xl rounded-md' contentExpandVertical>
            <RadioOption key={JSON.stringify(updateChoices[1])} option={updateChoices[1]} />
          </Card>
        </RadioGroup>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {() => (
          <StepButtons
            context={context}
            isSubmitting={false}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            nextBlocked={false}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
