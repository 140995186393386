import { LakehouseTableInput, ShareApp, Uni } from '@vendia/management-api-types'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { LakehouseTable } from 'src/utils/lakehouse/types'

import { ProductPolicies } from '../product-policies'

export const SharingPoliciesCard = ({ product }: { product: LakehouseTableInput }) => {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()

  const dataProduct = { ...product, ...product.tableDefinition } as LakehouseTable

  return (
    <Card className='w-full' padding='none' overflow='visible'>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between p-6 text-sm font-bold'>
          <h4>Data Filtering</h4>
          <div className='flex gap-2'>
            {/* <ButtonIconAction
                icon='pencil'
                label='Edit sharing policies'
                onClick={() => navigate('../data/edit-tables')}
              /> */}
            <ButtonIconAction
              type='button'
              icon={expanded ? 'chevron-up' : 'chevron-down'}
              label='Toggle data filtering details'
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </div>
        {expanded && (
          <div>
            <CardRule />
            <ProductPolicies product={dataProduct} />
          </div>
        )}
      </div>
    </Card>
  )
}
