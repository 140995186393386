import { isNonEmptyArray } from '@tanstack/react-form'
import {
  DataPolicyColumnEffect,
  LakehouseSyncScheduleEnum,
  LakehouseTableInput,
  Uni,
} from '@vendia/management-api-types'
import { useNavigate, useParams } from 'react-router'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import Loader from 'src/components/loaders/page-loader'
import { StepId } from 'src/pages/uni-create/config'
import { StepChooseSource } from 'src/pages/uni-create/flow-lakehouse/choose-source.step'
import { StepConnectCloudera } from 'src/pages/uni-create/flow-lakehouse/connect-cloudera.step'
import { StepConnectSnowflake } from 'src/pages/uni-create/flow-lakehouse/connect-snowflake.step'
import { StepCreateColumnSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-column-sharing-permissions.step'
import { StepCreateRowSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/create-row-sharing-permissions.step'
import { CreateVendiaTable } from 'src/pages/uni-create/flow-lakehouse/create-table.step'
import { StepJoinVendiaTables } from 'src/pages/uni-create/flow-lakehouse/join-vendia-tables.step'
import { StepReviewSharingPermissions } from 'src/pages/uni-create/flow-lakehouse/review-sharing-permissions.step'
import { StepSelectConnection } from 'src/pages/uni-create/flow-lakehouse/select-connection.step'
import { StepSelectTable } from 'src/pages/uni-create/flow-lakehouse/select-table.step'
import { StepSelectUpdateColumns } from 'src/pages/uni-create/flow-lakehouse/select-update-column.step'
import { StepSelectVendiaTables } from 'src/pages/uni-create/flow-lakehouse/select-vendia-tables.step'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import notify from 'src/utils/notify'
import { generateShortId } from 'src/utils/short-id'

export const CreateVendiaTableFlow = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni
  const { data } = useGetLakehouseData()
  const shareAppConfig = data?.currNodeConfig
  const { node: nodeName } = useParams()

  const navigate = useNavigate()
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni?.name })
  const { refetch: refetchLakehouseConfig } = useGetLakehouseData()
  const api = useApi()

  const existingTables = shareAppConfig?.tables ?? []

  const addConnection = async (connectionId: string, source: any) => {
    const connection = {
      id: connectionId,
      name: `connection-${connectionId}`,
      type: source.connection === 'snowflake' ? 'SNOWFLAKE' : 'CLOUDERA',
      [source.connection === 'snowflake' ? 'snowflakeConnection' : 'clouderaConnection']: source[source.connection],
    }
    return await api.addLakehouseConnection({
      input: {
        uniName: uni.name,
        nodeName: nodeName,
        connection,
      },
    })
  }

  if (!uni || !shareAppConfig) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <MultiStepFlow
      initialFlowState={{
        uniName: uni.name,
        nodeName: nodeName,
        // TODO this field is still named 'product' for now, but it really should be "table" or "target" for something more generic
        product: {
          name: '',
          columnPolicies: [],
          rowPolicies: [],
          defaultColumnPolicyEffect: DataPolicyColumnEffect.Exclude,
          syncSchedule: LakehouseSyncScheduleEnum.OnDemand,
          selectedVendiaTables: [],
        },
        source: {
          connection: undefined,
          availableTables: [],
          selectedTable: '',
          sourceRegion: '',
        },
        keyColumns: [],
        existingVendiaTables: existingTables,
        existingConfig: shareAppConfig,
        connectionId: '',
      }}
      flowSteps={[
        {
          id: StepId.CreateVendiaTable,
          StepComponent: CreateVendiaTable,
        },
        {
          id: StepId.ChooseSource,
          StepComponent: StepChooseSource,
        },
        {
          id: StepId.ConnectSnowflake,
          StepComponent: StepConnectSnowflake,
          showInStepNav: false,
        },
        {
          id: StepId.ConnectCloudera,
          StepComponent: StepConnectCloudera,
          showInStepNav: false,
        },
        {
          id: StepId.SelectConnection,
          StepComponent: StepSelectConnection,
          showInStepNav: false,
        },
        {
          id: StepId.SelectTable,
          StepComponent: StepSelectTable,
          showInStepNav: false,
        },
        {
          id: StepId.SelectVendiaTables,
          StepComponent: StepSelectVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.JoinVendiaTables,
          StepComponent: StepJoinVendiaTables,
          showInStepNav: false,
        },
        {
          id: StepId.SelectUpdateColumns,
          StepComponent: StepSelectUpdateColumns,
        },
        {
          id: StepId.ColumnSharingPermissions,
          StepComponent: StepCreateColumnSharingPermissions,
        },
        {
          id: StepId.RowSharingPermissions,
          StepComponent: StepCreateRowSharingPermissions,
        },
        {
          id: StepId.SharingPermissions,
          StepComponent: StepReviewSharingPermissions,
        },
      ]}
      onSubmit={async ({ value }) => {
        const { product, source } = value
        if (!value.connectionId && !isNonEmptyArray(product.selectedVendiaTables)) {
          // If there is no connectionId and no selected existing tables, the user is creating a new connection.
          value.connectionId = generateShortId({
            existingIds: shareAppConfig.connections.map((c) => c.id).filter((id): id is string => id !== undefined),
          })
          const addConnectionResponse = await addConnection(value.connectionId, source)
          if (addConnectionResponse.errors) {
            notify.error(addConnectionResponse.errors[0].message)
            return
          }
        }
        const existingIds = existingTables.map((product) => product.key)
        const shortId = generateShortId({ existingIds })
        existingIds.push(shortId)
        const newTableInput: LakehouseTableInput = {
          key: shortId,
          name: product.name,
          syncSchedule: product.syncSchedule,
          tableDefinition: {
            columnPolicies: product.columnPolicies,
            rowPolicies: product.rowPolicies,
            defaultColumnPolicyEffect: product.defaultColumnPolicyEffect,
          },
          sourceConnectionId: value.connectionId ?? '',
          sourceTableDefinitions: [
            {
              tableName: source.selectedTable,
              columns: value.selectedTableColumns,
              lastUpdatedColumn: value.lastUpdatedColumn,
              lastUpdatedColumnFormat: value.lastUpdatedColumnFormat,
              keyColumns: value.keyColumns,
            },
          ],
        }

        const res = await api.addLakehouseTable({
          input: {
            uniName: uni.name,
            nodeName: nodeName,
            table: newTableInput,
          },
        })

        refetchShareApps()
        refetchLakehouseConfig()
        navigate(`/uni/${uni.name}`)
      }}
    />
  )
}
