import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { Uni } from '@vendia/management-api-types'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import Form from 'src/components/fields/form'
import TextField from 'src/components/fields/text.field'
import Icon from 'src/components/icons/icon'
import Loader from 'src/components/loaders/page-loader'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import BasicModal from 'src/components/modals/modal.basic'
import PageHeader from 'src/components/page-header'
import { createFieldValidator } from 'src/utils/form/field-validators'
import { isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useGetUni } from 'src/utils/hooks/use-get-uni'
import useListUnis from 'src/utils/hooks/use-list-unis'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'
import notify from 'src/utils/notify'

enum SettingsStatus {
  Default,
  UpdateAliasSelected,
  UpdateConnectionSelected,
  DeleteAppSelected,
}

const PageLakehouseSettings = () => {
  const { id: uniName } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni as Uni | undefined

  if (!uni || !uniName) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return <PageLakehouseSettingsContent uni={uni} />
}

const PageLakehouseSettingsContent = ({ uni }: { uni: Uni }) => {
  const [status, setStatus] = useState(SettingsStatus.Default)
  const { status: uniStatus, name } = uni
  useDocumentTitle(`Application - ${name} - Settings - ${POSTFIX}`)

  if (!uniStatus) {
    return null
  }
  return (
    <div className='relative flex flex-grow flex-col'>
      <PageHeader
        title={`Settings`}
        aboveTitle={
          <Link to='../' className='text-link -mt-3 mb-6 flex items-center text-xs font-bold'>
            <Icon name='chevron-left' className={'mr-2'} size={16} />
            Return to dashboard
          </Link>
        }
        testid='lakehouse-settings-header'
      />
      <Card key='app-settings' title='Application Settings' className='m-6'>
        <div className='grid gap-12 p-6 pt-0'>
          <div className='flex items-center justify-between gap-6'>
            <div className='flex w-full flex-1 flex-col gap-2'>
              <h3 className='m-0 !mb-1 text-xl font-semibold'>Application Name</h3>
              <p className='max-w-sm text-balance text-sm text-gray-500'>
                A name for the application that can be changed at any time.
              </p>
              <p className='text-xs text-gray-500'>
                Application name is currently set to:{' '}
                {uni.alias ? <strong>{uni.alias}</strong> : <span className='italic text-gray-500'>None</span>}
              </p>
            </div>
            <div className='place-self-end'>
              <Button kind='secondary' onClick={() => setStatus(SettingsStatus.UpdateAliasSelected)}>
                Edit Application Name
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <EditAliasModal
        isOpen={status === SettingsStatus.UpdateAliasSelected}
        onClose={() => setStatus(SettingsStatus.Default)}
        uni={uni}
      />
      <DeleteApplicationModal
        isOpen={status === SettingsStatus.DeleteAppSelected}
        onClose={() => setStatus(SettingsStatus.Default)}
        application={uni}
      />
    </div>
  )
}

export default PageLakehouseSettings

const EditAliasModal = ({ isOpen, onClose, uni }: { isOpen: boolean; onClose: () => void; uni: Uni }) => {
  const api = useApi()
  const { listUnisQuery } = useListUnis()
  const { refetch: refetchUni } = useGetUni()
  const updateUniAliasMutation = useMutation({
    mutationFn: (alias: string) =>
      api.updateUniAlias({
        uniName: uni.name,
        alias: alias,
      }),
  })
  const form = useForm({
    defaultValues: {
      alias: uni.alias || '',
    },
    onSubmit: async ({ value }) => {
      const response = await updateUniAliasMutation.mutateAsync(value.alias)
      if (response?.errors?.length) {
        notify.error(response.errors[0].message)
        return
      }
      notify.success('Application name successfully updated')
      refetchUni()
      listUnisQuery.refetch()
      onClose()
    },
  })

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title='Edit Application name'
      buttons={
        <div className='flex items-center justify-end gap-2'>
          <Button kind='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button type='submit' form='editAliasForm'>
            Save
          </Button>
        </div>
      }
    >
      <Form id='editAliasForm' form={form}>
        <TextField
          form={form}
          name='alias'
          label='Application name'
          placeholder='Enter a human-readable name for the Application'
          validators={createFieldValidator({ validator: isRequiredValidator() })}
          removeFieldOnUnmount
        />
      </Form>
    </BasicModal>
  )
}

const DeleteApplicationModal = ({
  isOpen,
  onClose,
  application,
}: {
  isOpen: boolean
  onClose: () => void
  application: Uni
}) => {
  const api = useApi()
  const { listUnisQuery } = useListUnis()
  const navigate = useNavigate()

  const deleteUniMutation = useMutation({
    mutationFn: async () => {
      return api.destroy({ uniId: application.name })
    },
  })

  const onDeleteSubmit = async () => {
    try {
      const response = await deleteUniMutation.mutateAsync()
      if (response?.errors?.length) {
        notify.error(response.errors[0].message)
        return
      }
      notify.success('Application successfully deleted')
      listUnisQuery.refetch()
      navigate('/uni')
    } catch (e) {
      console.error('Unable to delete application', e)
      notify.error('Unable to delete application')
    }
    onClose()
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${application.alias}?`}
      onSubmit={onDeleteSubmit}
      isSubmitting={deleteUniMutation.isPending}
      actionButtonText={`Delete ${application.alias}`}
      confirmationType={'Application'}
      confirmationText={application.alias}
    >
      <div className='grid gap-4'>
        <p className='text-sm text-gray-500'>Are you sure you want to delete this Application and all of its tables?</p>
        <p className='text-sm text-gray-500'>This action cannot be undone.</p>
      </div>
    </ConfirmationModal>
  )
}
