import { useMutation, UseQueryResult } from '@tanstack/react-query'
import { LakehouseConnectionInput, LakehouseTableInput, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import Icon from 'src/components/icons/icon'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import Alert from 'src/components/messages/alert'
import HoverableTooltip from 'src/components/messages/hoverable-tooltip'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseTable } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

export type ExecutionStatus = 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'TIMED_OUT' | 'ABORTED' | 'PENDING_REDRIVE'

const failureStatuses = ['FAILED', 'TIMED_OUT', 'ABORTED'] as ExecutionStatus[]

export interface Job {
  source: JobStatus
}

// Copy/pasted from API
export interface JobStatus {
  status: ExecutionStatus
  startTime: string
  stopTime: string
  error?: string
  errorType?: string
}

// organize executions by data product key
export interface JobsByDataProductKey {
  [key: string]: {
    source: JobStatus
  }[]
}

export const TableCard = ({
  uni,
  nodeName,
  ownerNodeName,
  table,
  connection,
  tableIndex,
  isOwnedTable,
  setShareDataProduct,
  lakehouseJobsQueryResult,
  isLakehouseReady,
}: {
  uni: Uni
  nodeName: string
  ownerNodeName: string
  table: LakehouseTableInput
  connection?: LakehouseConnectionInput
  tableIndex: number
  isOwnedTable: boolean
  setShareDataProduct: (index: number) => void
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
  isLakehouseReady: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const jobs = lakehouseJobsQueryResult.data?.getLakehouseJobs?.jobs?.[table.key]
  const lakehouseTable = { ...table, ...table.tableDefinition } as LakehouseTable
  const sourceTable = table.sourceTableDefinitions[0]
  const api = useApi()

  const { refetch: refetchLakehouseJobs } = lakehouseJobsQueryResult

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, nodeName, tableKey }: { uniName: string; nodeName: string; tableKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, nodeName, tableKey }),

    onError: (error) => notify.error(`Error syncing table: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing table')
        return
      }
      notify.success(`Successfully initiated sync for: ${table.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  return (
    <Card key={table.key} className='w-full' padding='none'>
      <div className='flex w-full justify-between gap-6 p-4 px-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex gap-8'>
            <div className='grid w-60 place-items-center justify-items-start'>
              <div className='flex items-center gap-4'>
                <Icon name={'table'} size={32} className={'text-purple-900'} />
                {isLakehouseReady ? (
                  <Link to={`../data/tables/${table.name}`} className='text-link max-w-48 truncate text-lg font-bold'>
                    {table.name}
                  </Link>
                ) : (
                  <div className='max-w-48 truncate text-lg font-bold text-gray-400'>{table.name}</div>
                )}
              </div>
            </div>
            <div className='flex min-w-48 flex-col gap-2'>
              <div className='flex items-center justify-between text-xs text-gray-700'>Table Source</div>
              {isOwnedTable && (
                <>
                  {connection?.type === 'SNOWFLAKE' && (
                    <div className='flex items-center gap-2'>
                      <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-9' />
                      <div className='flex flex-col gap-0.5'>
                        <div className='text-sm font-bold'>Snowflake</div>
                        <div className='text-neutral-8 text-xs'>{sourceTable.tableName}</div>
                      </div>
                    </div>
                  )}
                  {connection?.type === 'CLOUDERA' && (
                    <div className='flex items-center gap-2'>
                      <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-9' />
                      <div className='flex flex-col gap-0.5'>
                        <div className='text-sm font-bold'>Cloudera</div>
                        <div className='text-neutral-8 text-xs'>{sourceTable.tableName}</div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {!isOwnedTable && (
                <div className='flex items-center gap-2'>
                  <img src='/logo-symbol.svg' alt='Vendia logo' className='size-9' />
                  <div className='flex flex-col gap-0.5'>
                    <div className='text-sm font-bold'>Vendia Node</div>
                    <div className='text-neutral-8 text-xs'>{ownerNodeName}</div>
                  </div>
                </div>
              )}
            </div>
            {isOwnedTable && (
              <div className='flex min-w-48 flex-col gap-2'>
                <div className='flex items-center justify-between text-xs text-gray-700'>Sync Status</div>
                <DataProductSyncStatus
                  product={table}
                  jobs={jobs}
                  isLakehouseReady={isLakehouseReady}
                  lakehouseJobsQueryResult={lakehouseJobsQueryResult}
                />
              </div>
            )}
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <ButtonIconAction
            icon={'refresh'}
            label='Sync table'
            onClick={() => setShowSyncModal(true)}
            disabled={!isLakehouseReady || jobs?.[0]?.source.status === 'RUNNING' || !isOwnedTable}
          />
          <ButtonIconAction
            icon={'share'}
            label='Share table'
            onClick={() => {
              setShareDataProduct(tableIndex)
            }}
            disabled={!isLakehouseReady || !isOwnedTable}
          />
          <ButtonIconAction
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            label='Toggle sharing rule details'
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <CardRule />
          <ProductPolicies product={lakehouseTable} canEdit={true} isLakehouseReady={isLakehouseReady} />
        </>
      ) : null}
      <ConfirmationModal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${table?.name}`}
        onSubmit={() =>
          lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, nodeName: nodeName, tableKey: table.key })
        }
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to <strong>{table.name}</strong>
          </p>
          <Alert className='text-sm italic text-gray-500'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
    </Card>
  )
}

const DataProductSyncStatus = ({
  jobs,
  isLakehouseReady,
  lakehouseJobsQueryResult,
}: {
  product: LakehouseTableInput
  jobs?: JobsByDataProductKey[string]
  isLakehouseReady: boolean
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
}) => {
  const lastSync = jobs?.[0]

  const isLastSyncRunning = lastSync?.source.status === 'RUNNING'

  const isLastSyncSuccess = !isLastSyncRunning && lastSync?.source.status === 'SUCCEEDED'

  let lastSuccessfulSync = lastSync
  if (!isLastSyncSuccess) {
    lastSuccessfulSync = jobs?.find((job) => {
      return job.source.status === 'SUCCEEDED'
    })
  }

  const lastSuccessfulSyncTime = lastSuccessfulSync ? lastSuccessfulSync.source.stopTime : null

  const syncfailureTime = lastSync && !isLastSyncSuccess ? lastSync.source.stopTime : null

  return (
    <div className='text-neutral-10 flex flex-col gap-1 text-xs'>
      {lakehouseJobsQueryResult.isLoading ? (
        <>
          <div className='flex gap-1'>
            <div>Last sync: </div>
            <div className='text-gray-500'>loading...</div>
          </div>
          <div className='flex gap-1'>
            <span>Last sync status: </span>
            <span className='text-gray-500'>loading...</span>
          </div>
        </>
      ) : (
        <>
          {isLakehouseReady ? (
            <div className='flex gap-1'>
              <div>Last sync: </div>
              {lastSuccessfulSyncTime ? (
                <HoverableTooltip
                  align='start'
                  content={
                    <span className='font-semibold'>
                      Sync succeeded on {dayjs(lastSuccessfulSyncTime).toDate().toLocaleString()}
                    </span>
                  }
                  side='bottom'
                  sideOffset={10}
                  isModern
                >
                  <span className='font-semibold'>{dayjs(lastSuccessfulSyncTime).fromNow()}</span>
                </HoverableTooltip>
              ) : (
                <div className='font-semibold'>Table not synced yet</div>
              )}
            </div>
          ) : (
            'Deploying...' // TODO: temporary, we want to show even when deploying if we already have data, but was broken for awhile
          )}
          {lastSync && isLastSyncSuccess ? (
            <div className='text-success-13 font-semibold'>
              <span>Last sync status: </span>
              <span className='text-success-12 font-bold'>Success</span>
            </div>
          ) : null}
          {lastSync && isLastSyncRunning ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1'>
                  <div className='mb-2 font-semibold'>Sync progress</div>
                  <div className='flex justify-between gap-8'>
                    <div>Syncing latest data from source:</div>
                    {lastSync?.source.status === 'RUNNING' ? (
                      <span className='text-information-10 font-semibold'>Syncing...</span>
                    ) : (
                      <span className='text-success-13 font-semibold'>Complete!</span>
                    )}
                  </div>
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-information-13 animate-pulse font-semibold'>
                <span>Last sync status: </span>
                <span className='text-information-12 font-bold'>Syncing...</span>
              </div>
            </HoverableTooltip>
          ) : null}
          {lastSync && !isLastSyncRunning && !isLastSyncSuccess ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1 text-wrap'>
                  {syncfailureTime ? (
                    <div className='mb-2 font-semibold'>Sync failed {dayjs(syncfailureTime).fromNow()}</div>
                  ) : null}
                  {lastSync?.source.error && (
                    <div>
                      {/* we could parse errors from source.error and show certain messages if any prove to be useful */}
                      An error occurred during our attempt to pull from your data source.
                    </div>
                  )}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-error-8'>
                <span>Last sync status: </span>
                {lastSync?.source.error && <span className='text-error-8 font-bold'>Error</span>}
              </div>
            </HoverableTooltip>
          ) : null}
        </>
      )}
    </div>
  )
}
