import { Label, Radio, RadioGroup } from '@headlessui/react'
import { FieldApi } from '@tanstack/react-form'
import { Node } from '@vendia/management-api-types'
import clsx from 'clsx'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import type { AccessLevel, Acl, Field, SharingRule } from 'src/utils/csv-uploader/csv-uploader'
import { generateSharingRulesFromAcl } from 'src/utils/csv-uploader/graphql.utils'
import { isRequiredOnChange } from 'src/utils/form/validation'
import { GetOrg } from 'src/utils/hooks/use-get-org'
import { GetUni, GetUniNodes } from 'src/utils/hooks/use-get-uni'

import { VendiaFormApi } from '../fields/form'
import Tooltip from '../messages/tooltip'
import { StickyTh } from './basic-table'

// We need to use another value that isn't a valid node name
// We then encode/decode '*' when reading/writing acls
export const ALL_PARTNERS_STAR = '_STAR'

type RadioGroupProps = {
  field: FieldApi<any, any>
  label?: string | JSX.Element
  options: {
    value: any
    label: string | JSX.Element
  }[]
  className?: string
  isNested?: boolean
  allPartnersAccessLevel: string
}

const RadioGroupRow = ({ field, label, options, isNested, className, allPartnersAccessLevel }: RadioGroupProps) => {
  const value = field.state.value
  const error = field.state.meta.errors?.at(0)

  function getisDisabled(option: string) {
    if (field.name === `accessLevel.${ALL_PARTNERS_STAR}`) return false
    if (isNested) return false
    if (allPartnersAccessLevel === 'CUSTOM') return false

    const optionsNumericValues: Record<string, number> = {
      NO_ACCESS: 0,
      READ: 1,
      READ_WRITE: 2,
      CUSTOM: 3,
    }

    const optionNumericValue = optionsNumericValues[option]
    const allPartnersAccessLevelNumericValue = optionsNumericValues[allPartnersAccessLevel]

    return optionNumericValue < allPartnersAccessLevelNumericValue
  }

  return (
    <RadioGroup
      defaultValue={value || 'NO_ACCESS'}
      value={value || 'NO_ACCESS'}
      onChange={(value) => {
        return field.handleChange(value)
      }}
      as={Fragment}
    >
      <tr className={clsx(className, error && 'bg-error-0', isNested && 'bg-uibg-0')}>
        <td
          className={clsx(
            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
            isNested && 'pl-8 sm:pl-12',
          )}
        >
          <Label>{label}</Label>
          {error && <span className='text-error-8 ml-4 font-semibold'>{error}</span>}
        </td>
        {options.map((option) => (
          <Radio
            key={JSON.stringify(option.value)}
            value={option.value}
            as={Fragment}
            disabled={getisDisabled(option.value)}
            aria-label={option.label}
          >
            {({ checked }) => (
              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                <Label data-testid={`sharing-option-${option.value}`}>
                  <div className='flex items-center justify-center'>
                    {checked ? (
                      getisDisabled(option.value) ? (
                        <div className='h-6 w-6 rounded-full ring-8 ring-inset' />
                      ) : (
                        <div className='ring-information-10 h-6 w-6 rounded-full ring-8 ring-inset' />
                      )
                    ) : getisDisabled(option.value) ? (
                      <div className='ring-information-2 h-6 w-6 rounded-full ring-1 ring-inset' />
                    ) : (
                      <div
                        className={`h-6 w-6 rounded-full ring-1 ring-inset ${
                          error ? 'ring-error-8' : 'ring-information-10'
                        }`}
                      />
                    )}
                  </div>
                  <div className='hidden'>{option.label}</div>
                </Label>
              </td>
            )}
          </Radio>
        ))}
        {isNested && <td />}
      </tr>
    </RadioGroup>
  )
}

type InitialTableState = {
  accessLevel: Record<string, AccessLevel | 'CUSTOM'>
  customAccessLevel: Record<string, Record<string, AccessLevel>>
}

export const generateInitialTableStateFromAcls = (
  acls: Acl[],
  partners: Node[],
  entityProperties: string[],
): InitialTableState => {
  const sharingRules: SharingRule[] = generateSharingRulesFromAcl({ acl: acls })
  const accessLevel: Record<string, AccessLevel | 'CUSTOM'> = {}
  const customAccessLevel: Record<string, Record<string, AccessLevel>> = {}

  const keys = partners.map((partner) => partner.name).concat('*')

  keys.forEach((key) => {
    const sharingRule = sharingRules.find((sharingRule) => sharingRule.partner === key)

    const mappedKey = key === '*' ? ALL_PARTNERS_STAR : key

    if (sharingRule) {
      if (sharingRule.fields.length === 1 && sharingRule.fields[0].name === '*') {
        accessLevel[mappedKey] = sharingRule.fields[0].accessLevel
      } else {
        accessLevel[mappedKey] = 'CUSTOM'
        customAccessLevel[mappedKey] = {}
        sharingRule.fields.forEach((field) => {
          customAccessLevel[mappedKey][field.name] = field.accessLevel
        })
        entityProperties.forEach((entityProperty) => {
          if (!customAccessLevel[mappedKey][entityProperty]) {
            customAccessLevel[mappedKey][entityProperty] = 'NO_ACCESS'
          }
        })
      }
    } else {
      accessLevel[mappedKey] = 'NO_ACCESS'
      customAccessLevel[mappedKey] = {}
    }
  })

  return {
    accessLevel,
    customAccessLevel,
  }
}

export const generateSharingRule = ({
  partnerName,
  rootAccessLevel,
  customAccessLevel,
}: {
  partnerName: string
  rootAccessLevel: AccessLevel | 'CUSTOM'
  customAccessLevel?: Record<string, AccessLevel>
}): SharingRule => {
  const finalPartnerName = partnerName === ALL_PARTNERS_STAR ? '*' : partnerName
  if (rootAccessLevel === 'CUSTOM') {
    const fields: Field[] = Object.entries(customAccessLevel!).map(([fieldName, accessLevel]) => ({
      name: fieldName,
      accessLevel,
    }))

    return {
      partner: finalPartnerName,
      fields: [...fields],
    }
  }
  return {
    partner: finalPartnerName,
    fields: [
      {
        name: '*',
        accessLevel: rootAccessLevel,
      },
    ],
  }
}

interface SharingRulesTableProps {
  form: VendiaFormApi<any>
  partners: (GetUniNodes[number] | { name: string; label: ReactNode })[]
  entityProperties: string[]
  hideCustom?: boolean // TODO: Remove after demo
}

export default function SharingRulesTable({ form, partners, entityProperties, hideCustom }: SharingRulesTableProps) {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const accessLevel = form.useStore((state) => state.values.accessLevel)
  const allPartnersAccessLevel = accessLevel ? accessLevel[ALL_PARTNERS_STAR] : 'NO_ACCESS'

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    } else if (allPartnersAccessLevel !== 'CUSTOM') {
      const newAccessLevel: Record<string, AccessLevel> = {
        [ALL_PARTNERS_STAR]: allPartnersAccessLevel,
      }
      partners.forEach((partner) => {
        newAccessLevel[partner.name] = allPartnersAccessLevel
      })
      form.setFieldValue('accessLevel', newAccessLevel)
    }
  }, [allPartnersAccessLevel])

  const baseRadioOptions = [
    { value: 'NO_ACCESS', label: 'No access' },
    { value: 'READ', label: 'View' },
    { value: 'READ_WRITE', label: 'View/Edit' },
  ]

  const radioOptions = hideCustom ? baseRadioOptions : [...baseRadioOptions, { value: 'CUSTOM', label: 'Custom' }] // TODO: Remove after demo

  const nestedRadioOptions = baseRadioOptions

  return (
    <>
      <div className='grid gap-4'>
        <div className='border-uibg-3 bg-uibg-1 w-full max-w-7xl rounded-xl border'>
          <div className='my-4 shadow-sm'>
            <table className='min-w-full table-auto divide-y divide-gray-300 pb-12'>
              <thead className='bg-gray-50'>
                <tr>
                  <StickyTh isFirstColumn className='w-1/3'>
                    Partner
                  </StickyTh>
                  <StickyTh isCentered>No access</StickyTh>
                  <StickyTh isCentered>View</StickyTh>
                  <StickyTh isCentered>View & edit</StickyTh>
                  {/* TODO: Remove after demo */}
                  {!hideCustom && <StickyTh isCentered>Custom</StickyTh>}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                <Fragment key={ALL_PARTNERS_STAR}>
                  <form.Field name={`accessLevel.${ALL_PARTNERS_STAR}`}>
                    {(field: FieldApi<any, any>) => (
                      <RadioGroupRow
                        field={field}
                        label={
                          <>
                            <span className='all-partners-label-text mr-1 font-semibold'>All partners</span>
                            <Tooltip size={14}>
                              <div style={{ width: 600 }}>
                                These permissions will apply to all current and future partners added to the network.
                                Granting permission to all partners does not allow disabling access to individual
                                partners.
                              </div>
                            </Tooltip>
                          </>
                        }
                        options={radioOptions}
                        key={ALL_PARTNERS_STAR}
                        allPartnersAccessLevel={allPartnersAccessLevel}
                      />
                    )}
                  </form.Field>

                  {accessLevel?.[ALL_PARTNERS_STAR] === 'CUSTOM' &&
                    entityProperties.map((entityProperty) => (
                      <form.Field
                        name={`customAccessLevel.${ALL_PARTNERS_STAR}.${entityProperty}`}
                        key={`${ALL_PARTNERS_STAR}.${entityProperty}`}
                      >
                        {(field) => (
                          <RadioGroupRow
                            field={field}
                            label={entityProperty}
                            options={nestedRadioOptions}
                            key={`${ALL_PARTNERS_STAR}.${entityProperty}`}
                            allPartnersAccessLevel={allPartnersAccessLevel}
                          />
                        )}
                      </form.Field>
                    ))}
                </Fragment>

                {partners.map((partner) => (
                  <Fragment key={partner.name}>
                    <form.Field name={`accessLevel.${partner.name}`} key={partner.name} validators={isRequiredOnChange}>
                      {(field) => (
                        <RadioGroupRow
                          field={field}
                          label={partner.name}
                          options={radioOptions}
                          allPartnersAccessLevel={allPartnersAccessLevel}
                        />
                      )}
                    </form.Field>
                    {accessLevel?.[partner.name] === 'CUSTOM' && accessLevel?.[ALL_PARTNERS_STAR] !== 'NO_ACCESS' ? (
                      <tr>
                        <td colSpan={5} className='bg-warning-0 text-balance p-4 px-24 text-center text-black'>
                          Note: <strong>All partners</strong> access controls that are more permissive than controls set
                          for this node will override these settings. You may wish to reduce{' '}
                          <strong>All partners</strong> access controls (or remove them entirely by setting to{' '}
                          <strong>No Access</strong>) when customizing permissions.
                        </td>
                      </tr>
                    ) : null}
                    {accessLevel?.[partner.name] === 'CUSTOM' &&
                      entityProperties.map((entityProperty) => (
                        <form.Field
                          name={`customAccessLevel.${partner.name}.${entityProperty}`}
                          key={`${partner.name}.${entityProperty}`}
                          validators={isRequiredOnChange}
                        >
                          {(field) => (
                            <RadioGroupRow
                              className='bg-gray-100'
                              isNested
                              label={entityProperty}
                              field={field}
                              options={nestedRadioOptions}
                              allPartnersAccessLevel={allPartnersAccessLevel}
                            />
                          )}
                        </form.Field>
                      ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

interface GetDefaultAccessLevelsParams {
  partners?: GetUniNodes
  entityProperties: string[]
  isNewRecord: boolean
}

export function getDefaultAccessLevels({ partners = [], entityProperties, isNewRecord }: GetDefaultAccessLevelsParams) {
  const defaultAccessLevel = isNewRecord ? 'READ_WRITE' : 'NO_ACCESS'
  const defaultAccessLevels: Record<string, Record<string, any>> = {
    accessLevel: {},
    customAccessLevel: {},
  }

  const partnersWithAll = [{ name: ALL_PARTNERS_STAR }, ...partners]

  partnersWithAll.forEach((partner) => {
    // NOTE: If we default to anything other than NO_ACCESS, we'll need to fix a bug where
    // if a Node was set to "No Access", on the next edit page load it gets set to whatever
    // the default is. That's because in getInitialAccessLevels there is no value in the
    // existing acl to override the default.
    defaultAccessLevels.accessLevel[partner.name] = defaultAccessLevel
    defaultAccessLevels.customAccessLevel[partner.name] = {}
    entityProperties.forEach((entityProperty) => {
      defaultAccessLevels.customAccessLevel[partner.name][entityProperty] = defaultAccessLevel
    })
  })

  return defaultAccessLevels
}

interface EntityInstance {
  _acl?: Acl[]
}

interface GetInitialAccessLevelsParams {
  entityInstance?: EntityInstance
  partners?: GetUniNodes
  entityProperties: string[]
  isNewRecord: boolean
}

export function getInitialAccessLevels({
  entityInstance,
  partners,
  entityProperties,
  isNewRecord,
}: GetInitialAccessLevelsParams) {
  const initialAccessLevels = getDefaultAccessLevels({ partners, entityProperties, isNewRecord })

  if (!entityInstance || !entityInstance._acl) return initialAccessLevels

  const acl = entityInstance._acl

  acl.forEach((a) => {
    a.principal.nodes.forEach((n) => {
      const partner = n === '*' ? ALL_PARTNERS_STAR : n
      if (!a.path) {
        if (a.operations?.[0] === 'ALL') {
          initialAccessLevels.accessLevel[partner] = 'READ_WRITE'
        } else if (a.operations?.[0] === 'READ') {
          initialAccessLevels.accessLevel[partner] = 'READ'
        }
      } else {
        initialAccessLevels.accessLevel[partner] = 'CUSTOM'
        if (a.operations?.[0] === 'ALL') {
          initialAccessLevels.customAccessLevel[partner][a.path] = 'READ_WRITE'
        } else if (a.operations?.[0] === 'READ') {
          initialAccessLevels.customAccessLevel[partner][a.path] = 'READ'
        }
      }
    })
  })

  return initialAccessLevels
}
