import { useMonaco } from '@monaco-editor/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import CopyToClipboardButton from 'src/components/buttons/copy-to-clipboard-button'
import Card from 'src/components/containers/card'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import PageLoader from 'src/components/loaders/page-loader'
import { BasicTable } from 'src/components/tables/basic-table'
import { StepId } from 'src/pages/uni-create/config'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { getIcebergTableName } from 'src/utils/lakehouse/products'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

export const StepJoinVendiaTables: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const { uniName, nodeName, product } = context.form.useStore((state) => state.values)
  const selectedTables = product.selectedVendiaTables || []
  const [columns, setColumns] = useState<string[]>([])
  const [rows, setRows] = useState<any[][]>([])
  const monaco = useMonaco()
  const api = useApi()
  const expectedTableNames = selectedTables.map((table) => getIcebergTableName(uniName!, nodeName!, table.key))

  const validateQuery = async (query?: string): Promise<string> => {
    try {
      if (!query?.trim()) {
        return 'Query cannot be empty'
      }

      const missingTables = expectedTableNames.filter((tableName) => !query.toLowerCase().includes(tableName))
      if (missingTables.length > 0) {
        return `Query must include all selected tables: ${missingTables.join(', ')}`
      }

      if (!query.toLowerCase().includes('join')) {
        return 'Query must include a JOIN statement'
      }

      return ''
    } catch (error) {
      return `Query validation error: ${error instanceof Error ? error.message : 'Unknown error'}`
    }
  }

  const fetchData = useMutation({
    mutationFn: async () =>
      api.fetchLakehouseDataPreview({
        uniName: uniName!,
        nodeName: nodeName!,
        joinTableKey: selectedTables[0].key, // janky but the resolver checks to make sure the table key belongs to the lakehouse app
        joinQuery: product.joinSql,
      }),
    onSuccess: (response: any) => {
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      const results = response.fetchLakehouseDataPreview
      const columns = Object.keys(results[0])
      setColumns(columns)
      setRows(results?.map((row: any) => columns.map((header) => row[header])) ?? [])
    },
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Create a Join Query'
        description='Write a SQL query to join your selected Vendia tables'
      />
      <ScrollableStepContent inset>
        <Card className='ml-2 mr-1 min-h-64 w-full max-w-4xl rounded-md' padding='small'>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between p-6 text-sm font-bold'>
              <h2>Join your tables with SQL</h2>
              <ButtonIconAction
                icon='play'
                label='Run sql query'
                type='submit'
                onClick={async () => {
                  setColumns([])
                  setRows([])
                  const validationResult = await validateQuery(product.joinSql)
                  if (!validationResult) {
                    fetchData.mutate()
                  }
                }}
              />
            </div>
            <MonacoEditorField
              form={context.form}
              name='product.joinSql'
              language='sql'
              minHeight={150}
              validators={isRequiredOnBlur}
              onChange={(value?: string) => {
                if (!monaco) return
                if (value !== undefined) {
                  validateQuery(value).then((validationResult) => {
                    const model = monaco.editor.getModels()[0]
                    if (!validationResult) {
                      monaco.editor.setModelMarkers(model, 'owner', [])
                    } else {
                      monaco.editor.setModelMarkers(model, 'owner', [
                        {
                          startLineNumber: 1,
                          startColumn: 1,
                          endLineNumber: 1,
                          endColumn: 1,
                          message: validationResult,
                          severity: monaco.MarkerSeverity.Error,
                        },
                      ])
                    }
                  })
                }
              }}
              options={{
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                lineNumbers: 'on' as any,
              }}
            />
            <h5>
              <b>Available Tables:</b>
            </h5>
            <ul>
              {expectedTableNames.map((tableName) => (
                <li key={tableName} className='flex items-center'>
                  <span className='mr-2'>{tableName}</span>
                  <CopyToClipboardButton iconSize={16} text={tableName} />
                </li>
              ))}
            </ul>
          </div>
        </Card>
        <Card className='ml-1 mr-2 w-full' padding='none' contentExpandVertical>
          <div className='flex flex-col gap-4'>
            {fetchData.isIdle && <div className='p-6'>Run a query to see results</div>}
            {fetchData.isPending && (
              <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                <PageLoader />
              </div>
            )}
            {fetchData.isSuccess && (
              <div>
                {fetchData.data.fetchLakehouseDataPreview.length === 0 ? (
                  <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                    No data found
                  </div>
                ) : (
                  <BasicTable columnHeaders={columns} rows={rows} />
                )}
              </div>
            )}
            {fetchData.isError && <div className='p-6 text-red-500'>{fetchData.error.message}</div>}
          </div>
        </Card>
      </ScrollableStepContent>
      <context.form.Subscribe selector={(state) => [state.canSubmit]}>
        {([canSubmit]) => (
          <StepButtons
            context={context}
            nextBlocked={!canSubmit || !fetchData.isSuccess || !columns.length}
            onNext={() => {
              context.form.setFieldValue(
                'selectedTableColumns',
                // TODO we have to force this structure due to the final input type
                // fix with https://vendia-data.atlassian.net/browse/VENDIA-1618
                columns.map((column) => ({
                  name: column,
                  nullable: true,
                  primaryKey: false,
                  uniqueKey: false,
                  comment: null,
                  snowflakeType: 'STRING',
                })),
              )
              context.form.setFieldValue('joinTableKey', selectedTables[0].key)
              context.goToStep(StepId.SelectUpdateColumns)
            }}
          />
        )}
      </context.form.Subscribe>
    </StepWrapper>
  )
}
