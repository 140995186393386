import { useForm } from '@tanstack/react-form'
import { LakehouseTableInput, ShareApp, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import Form from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import Icon from 'src/components/icons/icon'
import { SharingPoliciesCard } from 'src/components/lakehouse/tables/sharing-policies-card'
import { SyncStatusCard } from 'src/components/lakehouse/tables/sync-status-card'
import { TableDetailsCard } from 'src/components/lakehouse/tables/table-details-card'
import PageLoader from 'src/components/loaders/page-loader'
import PageHeader from 'src/components/page-header'
import { BasicTable } from 'src/components/tables/basic-table'
import { LakehouseExternalAccess, LakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useGetLakehouseData } from 'src/utils/hooks/use-get-lakehouse-data'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

import { ShareTableModal } from './share-data-product-modal'

enum State {
  INITIALIZED,
  FETCHING_DATA,
  DATA_FETCH_SUCCESSFUL,
  DATA_FETCH_FAILED,
}

// Both SQL safe and lowercase (for glue database/table names)
function getSafeUniNodeName(uniName: string, nodeName: string) {
  return `${uniName}_${nodeName}`.replace(/-/g, '_').replace(/\./g, '_').toLowerCase()
}

export const PageLakehouseTable = () => {
  const { data: uniData } = useGetUni()
  const uni = uniData?.getUni as Uni | undefined
  const { data: lakehouseData } = useGetLakehouseData()
  const shareApps = lakehouseData?.rawShareApps ?? []
  const shareAppConfig = lakehouseData?.currNodeConfig

  if (!uni || !shareAppConfig) {
    return <PageLoader />
  }

  return <PageLakehouseTableContent uni={uni} shareApps={shareApps} shareAppConfig={shareAppConfig} />
}

const PageLakehouseTableContent = ({
  uni,
  shareApps,
  shareAppConfig,
}: {
  uni: Uni
  shareApps: ShareApp[]
  shareAppConfig: LakehouseShareAppConfig
}) => {
  const { table: tableName, node: nodeName } = useParams<{ table: string; node: string }>()

  const table = useMemo(
    () => shareAppConfig?.tables.find((p) => p.name === tableName) || shareAppConfig?.tables[0],
    [tableName, shareAppConfig],
  )

  type LakehouseData = {
    [key: string]: any
  }
  const [data, setData] = useState<LakehouseData[]>([])
  const api = useApi()
  const [state, setState] = useState(State.INITIALIZED)
  const [expanded, setExpanded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const buildSqlQuery = (uni: Uni, shareAppConfig: LakehouseShareAppConfig, table: LakehouseTableInput) => {
    const prefix = 'iceberg'
    const tableKey = table.key
    const safeUniNodeName = getSafeUniNodeName(uni.name, nodeName!)
    const icebergTable = `${prefix}_${safeUniNodeName}_${tableKey}`.toLowerCase()
    return `SELECT * FROM ${icebergTable} limit 10;`
  }

  const form = useForm<any>({
    defaultValues: {
      warehouse_sql_editor: buildSqlQuery(uni, shareAppConfig, table),
    },
    onSubmit: async ({ value }: any) => {
      setState(State.FETCHING_DATA)
      const response = await api.fetchLakehouseData({
        uniName: uni.name,
        nodeName: nodeName!,
        tableKey: table.key,
        sqlText: value.warehouse_sql_editor?.trim(),
      })
      if (response.errors?.length) {
        setErrorMessage(response.errors[0].message)
        setState(State.DATA_FETCH_FAILED)
        return
      }
      setData(response.fetchLakehouseData.results)
      setState(State.DATA_FETCH_SUCCESSFUL)
    },
  })

  const columnHeaders = Object.keys(data?.[0] ?? {})
  const rows =
    data?.map((row) => {
      // map over headers to ensure order and handle missing values
      return columnHeaders.map((header) => row[header])
    }) ?? []

  useEffect(() => {
    form.handleSubmit()
  }, [])

  return (
    <div className='flex flex-grow flex-col gap-4'>
      <PageHeader
        title={`${tableName}`}
        aboveTitle={
          <Link to='../' className='text-link -mt-3 mb-6 flex items-center text-xs font-bold'>
            <Icon name='chevron-left' className={'mr-2'} size={16} />
            Return to dashboard
          </Link>
        }
        testid='product-name-header'
        image={<img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />}
      />
      <div className='flex gap-6 p-6'>
        <div className='w-2/3'>
          <Card className='w-full' padding='none' overflow='visible'>
            <Form form={form}>
              <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between p-6 text-sm font-bold'>
                  <h2>Query your data with SQL</h2>
                  <div className='flex gap-2'>
                    <ButtonIconAction icon='play' label='Run sql query' type='submit' />
                    <ButtonIconAction
                      type='button'
                      icon={expanded ? 'chevron-up' : 'chevron-down'}
                      label='Toggle sharing rule details'
                      onClick={() => setExpanded(!expanded)}
                    />
                  </div>
                </div>
                {expanded && (
                  <div>
                    <CardRule />
                    <div className='bg-uibg-0 p-6'>
                      <div className='grid'>
                        <MonacoEditorField
                          form={form}
                          language='sql'
                          name='warehouse_sql_editor'
                          validators={isRequiredOnBlur}
                          minHeight={150}
                          options={{
                            lineNumbers: 'on' as any,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </Card>
          <Card className='w-full' padding='none'>
            <div className='flex flex-col gap-4'>
              {state === State.INITIALIZED && <div className='p-6'>Run a query to see results</div>}
              {state === State.FETCHING_DATA && (
                <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border text-center'>
                  <PageLoader />
                </div>
              )}
              {state === State.DATA_FETCH_SUCCESSFUL && (
                <div>
                  {rows.length === 0 ? (
                    <div className='border-uiBg-3 bg-uibg-0 flex min-h-48 items-center justify-center border'>
                      No data found
                    </div>
                  ) : (
                    <BasicTable columnHeaders={columnHeaders} rows={rows} />
                  )}
                </div>
              )}
              {state === State.DATA_FETCH_FAILED && <div className='p-6'>{errorMessage}</div>}
            </div>
          </Card>
        </div>
        <div className='w-1/3 space-y-4'>
          <SyncStatusCard uni={uni} nodeName={nodeName!} product={table} />
          <SharingPoliciesCard product={table} />
          <TableDetailsCard uni={uni} nodeName={nodeName!} tableKey={table.key} />
          {/* TODO: hiding this until we get back on enabling distribution */}
          {/* <InvitesCard
            product={table}
            shareAppConfig={shareAppConfig}
            distributionShareAppConfig={distributionShareAppConfig}
            uni={uni}
          /> */}
        </div>
      </div>
    </div>
  )
}

const InvitesCard = ({
  product,
  shareAppConfig,
  distributionShareAppConfig,
  uni,
}: {
  product: LakehouseTableInput
  shareAppConfig: LakehouseShareAppConfig
  uni: Uni
  distributionShareAppConfig: LakehouseShareAppConfig
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showShareTableModal, setShowShareTableModal] = useState(false)

  const api = useApi()

  const invites = distributionShareAppConfig?.externalAccess

  const RenderInviteStatus = (invite: LakehouseExternalAccess) => {
    const days = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'day')
    const hours = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'hour') % 24
    const minutes = dayjs(invite.grantedEmailTokenTtl).diff(dayjs(), 'minute') % 60

    const isExpired = dayjs(invite.grantedEmailTokenTtl).isBefore(dayjs())
    const timeLeft = days > 0 ? `${days} days left` : hours > 0 ? `${hours} hours left` : `${minutes} minutes left`

    switch (invite.status) {
      case 'PENDING_CONNECTION':
        if (isExpired) {
          return (
            <div
              className='text-link cursor-pointer text-base font-bold hover:text-[#0b2e56]'
              onClick={() => setShowShareTableModal(true)}
            >
              Resend
            </div>
          )
        }
        return <div className='text-base font-bold text-gray-600'>{timeLeft}</div>
      case 'CONNECTION_REQUESTED':
        return <div className='text-base font-bold text-gray-600'>Connection requested</div>
      case 'CONNECTED':
        return <div className={`text-success-13 text-base font-bold`}>Accepted</div>
      case 'CONNECTION_FAILED':
        return <div className='text-error-8 text-base font-bold'>Failed</div>
      default:
        return <div></div>
    }
  }

  const InviteItem = ({ invite }: { invite: LakehouseExternalAccess }) => {
    return (
      <div className='flex items-center justify-between gap-3 text-nowrap'>
        <div className='max-w-72 truncate text-base font-bold'>{invite.grantedEmailAddress}</div>
        <div>{RenderInviteStatus(invite)}</div>
      </div>
    )
  }

  return (
    <>
      <Card className='w-full' padding='none' overflow='visible'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-between p-6 text-sm font-bold'>
            <h4>Invites</h4>
            <div className='flex gap-2'>
              <ButtonIconAction
                icon={'share'}
                label='Share table'
                onClick={() => {
                  setShowShareTableModal(true)
                }}
              />
              <ButtonIconAction
                type='button'
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                label='Toggle sharing rule details'
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </div>
          {expanded && (
            <div>
              <CardRule />
              <div className='bg-uibg-0 p-6'>
                <div className='grid'>
                  {!invites ? (
                    <div>No invites found</div>
                  ) : (
                    <ul className='space-y-4'>
                      {invites.map((invite, index) => (
                        <li key={index}>
                          <InviteItem invite={invite} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <ShareTableModal
        uni={uni}
        isOpen={showShareTableModal}
        onClose={() => setShowShareTableModal(false)}
        shareAppConfig={shareAppConfig}
        selectedTable={product.key}
        onlyAllowDefaultProduct={true}
      />
    </>
  )
}
