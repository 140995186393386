import { useMutation } from '@tanstack/react-query'
import { LakehouseClouderaConnectionInput, LakehouseSnowflakeConnectionInput } from '@vendia/management-api-types'
import debug from 'debug'
import { useNavigate } from 'react-router'
import Card, { CardRule } from 'src/components/containers/card'
import ListboxField from 'src/components/fields/listbox.field'
import RadioGroupField from 'src/components/fields/radio-group.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { getEmptySchema } from 'src/components/schema-designer/utils'
import { isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:selectTable')

export const StepSelectTable: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const navigate = useNavigate()

  const { connectionId, nodeName, source, uniName } = form.useStore((s) => s.values)
  const tableRef = useFirstFocus<any>()

  const handleTableChange = async (newTable?: string, previousTable?: string) => {
    if (previousTable && previousTable !== newTable) {
      // TODO: use a nicer modal here
      const confirm = window.confirm(
        'Changing the table will reset the Sharing permissions you have already configured. Are you sure you want to continue?',
      )
      if (!confirm) {
        return
      }
      form.setFieldValue('selectedTableColumns', [])
      setTimeout(() => context.goToStep(StepId.DataProducts))
    }
  }

  const getTableSchemaMutation = useMutation<any, Error>({
    mutationFn: async () => {
      const args = {
        tableName: source.selectedTable,
        uniName,
        nodeName,
        ...(connectionId && { connectionId }),
      }
      if (source.connection === 'snowflake') {
        return api.getLakehouseTableSchema({
          ...args,
          snowflakeConnection: source.snowflake as LakehouseSnowflakeConnectionInput,
        })
      } else if (source.connection === 'cloudera') {
        return api.getLakehouseTableSchema({
          ...args,
          clouderaConnection: source.cloudera as LakehouseClouderaConnectionInput,
        })
      }
    },
    onError: (error: Error) => notify.error(`Error fetching table schema: ${error}`),
    onSuccess: (response: any) => {
      logger('response', response)
      if (response.message) {
        notify.error(`Error fetching table schema: ${response.message}`)
        return
      }
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      form.setFieldValue('selectedTableColumns', response.getLakehouseTableSchema)
      form.setFieldValue('schema', JSON.stringify(getEmptySchema()))
      context.goToStep(StepId.SelectUpdateColumns)
    },
  })

  const { errorMap, errors, canSubmit, fieldMeta } = form.useStore((s) => s)
  logger('select table', {
    isPending: getTableSchemaMutation.isPending,
    canSubmit,
    errors,
    errorMap,
    fieldMeta,
    form,
  })

  const tableOptions = source?.availableTables?.map((v: string) => ({ value: v, label: v }))

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Select a source table'
        description={'Select the table containing the data set you want to share.'}
      />
      <ScrollableStepContent inset>
        <Card className='min-h-64 w-full max-w-4xl rounded-md' contentExpandVertical>
          <div className='flex w-full flex-grow flex-col gap-4'>
            <div className='w-full text-lg font-bold'>
              <span className='capitalize'>{source.connection}</span> tables
            </div>
            <CardRule className='-mx-10' />

            {tableOptions && tableOptions.length > 7 ? (
              <div className='flex flex-grow flex-col pt-6'>
                <ListboxField
                  ref={tableRef}
                  name='source.selectedTable'
                  label='Table'
                  description='Select the table containing the data set you want to share.'
                  options={tableOptions}
                  validators={lakehouseFlowFieldValidator({
                    stepId: StepId.SelectTable,
                    validator: isRequiredValidator(),
                  })}
                  onChange={handleTableChange}
                  useNestedLabel
                  form={form}
                />
              </div>
            ) : (
              <RadioGroupField
                name='source.selectedTable'
                options={tableOptions}
                defaultValue={tableOptions?.[0]?.value}
                validators={lakehouseFlowFieldValidator({
                  stepId: StepId.SelectTable,
                  validator: isRequiredValidator(),
                })}
                onChange={handleTableChange}
                form={form}
              />
            )}
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {([canSubmit]) => (
          <StepButtons
            context={context}
            isSubmitting={getTableSchemaMutation.isPending}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            nextBlocked={getTableSchemaMutation.isPending || !canSubmit}
            onNext={() => getTableSchemaMutation.mutate()}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
