import debug from 'debug'
import { useNavigate } from 'react-router'
import Card, { CardRule } from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { EditRowPolicies } from 'src/components/lakehouse/edit-row-policies'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

const logger = debug('app:sharingPermissions')

export const StepCreateRowSharingPermissions: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const navigate = useNavigate()
  const form = context.form

  const { product, flowSteps, currStepIndex, currStep } = form.useStore((state) => state.values)
  const buttonRef = useFirstFocus<HTMLButtonElement>()

  logger('product', {
    product,
    flowSteps,
    currStepIndex,
    currStep,
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Define row-level filtering rules'
        description='Configure fine-grained data access rules to share data securely'
      />
      <ScrollableStepContent inset>
        <Card className='h-full w-full max-w-7xl' childrenWrapperClassName='h-full'>
          <div className='flex w-full flex-col gap-4 px-4'>
            <div>
              <div className='w-full text-lg font-bold'>Row permissions</div>
              <div className='text-neutral-9 w-full text-sm'>
                Refine data access by restricting which rows are shared in your table.
              </div>
            </div>
            <CardRule className='-mx-10' />
            <EditRowPolicies context={context} policiesForm={context.form} fieldPath='product' />
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            hasCancel={true}
            onCancel={() => navigate('../', { relative: 'path' })}
            nextButtonRef={buttonRef}
            nextBlocked={!canSubmit || isPristine}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
