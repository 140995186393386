import { FieldApi, useField } from '@tanstack/react-form'
import { DataPolicyOperator, DataPolicyRowEffect, RowPolicyInput } from '@vendia/management-api-types'
import clsx from 'clsx'
import debug from 'debug'
import { StepId } from 'src/pages/uni-create/config'
import { getValue } from 'src/utils/form/form'
import { isRequiredValidator } from 'src/utils/form/validation'
import { dataProductOperatorOptions, getColumnOptions } from 'src/utils/lakehouse/flows'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import Button from '../buttons/button'
import ListboxField from '../fields/listbox.field'
import TextField from '../fields/text.field'
import { StepComponentProps, VendiaFlowFormApi } from '../flows/types'
import Icon from '../icons/icon'

const logger = debug('app:sharingPermissions')

export const EditRowPolicies = ({
  context,
  policiesForm,
  fieldPath,
}: StepComponentProps<LakehouseFlowStepValues> & { policiesForm: VendiaFlowFormApi<any>; fieldPath?: string }) => {
  const selectedTableColumns = context.form.useStore((state) => state.values.selectedTableColumns)
  const { rowPolicies } = policiesForm.useStore((state) =>
    fieldPath ? getValue(state.values, fieldPath) : state.values,
  )

  const field = useField<any, any>({
    name: fieldPath ? `${fieldPath}.rowPolicies` : 'rowPolicies',
    form: policiesForm,
    mode: 'array',
    defaultValue: rowPolicies,
  })

  const snowflakeColumnOptions = getColumnOptions(selectedTableColumns, rowPolicies)

  async function addRowPolicy(field: FieldApi<any, any>) {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue({ name: '', effect: DataPolicyRowEffect.Allow, operator: '', value: '' })
    }
  }

  const clearRowPolicy = (index: number) => {
    const policyAttributes = policiesForm.getFieldValue(`product.rowPolicies.${index}`)
    field.removeValue(index)
    // This is required because removeValue() does not remove the nested fields
    Object.keys(policyAttributes).forEach((attribute) =>
      policiesForm.deleteField(`product.rowPolicies.${index}.${attribute}`),
    )
  }

  return (
    <div className='flex w-full flex-1 flex-col'>
      {rowPolicies?.length === 0 && (
        <>
          <h1 className='m-2 self-center text-xl'>Configure row filtering using rules.</h1>
          <h2 className='self-center text-lg'>
            By default, <strong>all</strong> rows are included without defined rules.
          </h2>
        </>
      )}
      {rowPolicies?.length > 0 && (
        <div
          className={clsx(
            'mb-4 flex h-5/6 w-full max-w-7xl flex-col justify-center gap-4',
            // h.scroll entire rows area on smaller screens
            'min-w-[1100px]',
          )}
        >
          {rowPolicies?.map((policy: RowPolicyInput, index: number) => {
            const fieldPrefix = fieldPath ? `${fieldPath}.rowPolicies.${index}` : `rowPolicies.${index}`
            logger('row policy', { fieldPrefix, index, policy })

            const requireConstraint =
              policy.operator && ![DataPolicyOperator.IsNotNull, DataPolicyOperator.IsNull].includes(policy.operator)

            return (
              <div
                key={index}
                className={'bg-uibg-1 rounded-md border border-indigo-300 px-4 py-2'}
                data-testid={`row-sharing-policy-${index}`}
              >
                <div className='flex items-center gap-3'>
                  <div className='flex w-full items-center gap-4 pb-2'>
                    <span className='text-neutral-8 min-w-36 self-center'>
                      <strong> {index === 0 ? 'Filter data,' : 'and'}</strong>
                      {' where'}
                    </span>
                    <ListboxField
                      name={`${fieldPrefix}.name`}
                      label='Attribute'
                      options={snowflakeColumnOptions}
                      data-testid={`row-policy-attribute-select-${index}`}
                      validators={lakehouseFlowFieldValidator({
                        stepId: StepId.SharingPermissions,
                        validator: isRequiredValidator(),
                      })}
                      useNestedLabel
                      setWidthAccordingToOptions
                      form={policiesForm}
                      wrapperClassName='shrink !w-auto'
                    />
                    <span className='text-neutral-8 self-center'>is</span>
                    <ListboxField
                      name={`${fieldPrefix}.operator`}
                      label='Operator'
                      options={dataProductOperatorOptions}
                      data-testid={`row-policy-operator-select-${index}`}
                      className='min-w-[13rem]'
                      validators={lakehouseFlowFieldValidator({
                        stepId: StepId.SharingPermissions,
                        validator: isRequiredValidator(),
                      })}
                      useNestedLabel
                      setWidthAccordingToOptions
                      form={policiesForm}
                      wrapperClassName='shrink !w-auto'
                    />
                    {requireConstraint && (
                      <TextField
                        name={`${fieldPrefix}.value`}
                        label='Constraint'
                        data-testid={`row-policy-value-input-${index}`}
                        className='min-w-32'
                        wrapperClassName='shrink !w-auto'
                        validators={lakehouseFlowFieldValidator({
                          stepId: StepId.SharingPermissions,
                          validator: isRequiredValidator(),
                        })}
                        useNestedLabel
                        form={policiesForm}
                      />
                    )}
                  </div>
                  <div className={'p-2'}>
                    <Button kind='link' icon='trash' onClick={() => clearRowPolicy(index)} />
                  </div>
                </div>
                {field.state.meta.errors?.length > 0 && (
                  <div className='flex gap-1 text-balance py-1 text-left text-xs'>
                    <Icon name='error' size='xs' />
                    {field.state.meta.errors.at(0)}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}

      <div className='flex w-full max-w-4xl pb-6'>
        <Button
          kind={'secondary'}
          onClick={(e) => {
            e.preventDefault()
            addRowPolicy(field)
          }}
          icon='plus-m'
          iconSize={14}
        >
          {field.state.value?.length > 0 ? 'Add another' : 'Add rule'}
        </Button>
      </div>
    </div>
  )
}
