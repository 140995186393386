import { useMutation } from '@tanstack/react-query'
import {
  LakehouseClouderaConnectionInput,
  LakehouseConnectionTypeEnum,
  LakehouseSnowflakeConnectionInput,
} from '@vendia/management-api-types'
import Card, { CardRule } from 'src/components/containers/card'
import RadioGroupField from 'src/components/fields/radio-group.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import notify from 'src/utils/notify'

import { StepId } from '../config'

export const StepSelectConnection: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const { connectionId, existingConfig, nodeName, uniName } = form.useStore((s) => s.values)
  const connectionOptions = existingConfig.connections.map((connection: any) => ({
    value: connection.id,
    label: connection.name,
  }))
  const api = useApi()

  const handleConnectionChange = async (connectionId?: string, previousConnectionId?: string) => {
    if (previousConnectionId && previousConnectionId !== connectionId) {
      const confirm = window.confirm(
        'Changing the connection will reset any table and column/row policy selections. Are you sure you want to continue?',
      )

      if (!confirm) {
        return
      }
      form.setFieldValue('source.availableTables', [])
      form.setFieldValue('source.connection', undefined)
      form.setFieldValue('source.snowflake', undefined)
      form.setFieldValue('source.cloudera', undefined)
      form.setFieldValue('source.selectedTable', '')
    }
  }

  const getTablesMutation = useMutation({
    mutationFn: () => {
      const selectedConnection = existingConfig.connections.find((c: any) => c.id === connectionId)
      form.setFieldValue('source.connection', selectedConnection.type.toLowerCase())
      form.setFieldValue(
        'source.snowflake',
        selectedConnection.snowflakeConnection as LakehouseSnowflakeConnectionInput,
      )
      form.setFieldValue('source.cloudera', selectedConnection.clouderaConnection as LakehouseClouderaConnectionInput)
      return api.getLakehouseTables({
        uniName,
        nodeName,
        connectionInput: {
          id: connectionId,
          type:
            selectedConnection.type === LakehouseConnectionTypeEnum.Snowflake
              ? LakehouseConnectionTypeEnum.Snowflake
              : LakehouseConnectionTypeEnum.Cloudera,
          clouderaConnection: selectedConnection.clouderaConnection as LakehouseClouderaConnectionInput,
          snowflakeConnection: selectedConnection.snowflakeConnection as LakehouseSnowflakeConnectionInput,
        },
      })
    },
    onSuccess: (response) => {
      form.setFieldValue('source.availableTables', response.getLakehouseTables.tables as string[])
      context.goToStep(StepId.SelectTable)
    },
    onError: (error) => notify.error(`Error fetching tables with connection: ${error}`),
  })

  const handleNext = async () => {
    const validate = await form.validateField('connectionId', 'blur')
    if (validate?.length) {
      return
    }
    getTablesMutation.mutate()
  }

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Select an exsting connection'
        description='Select an existing connection already configured in this uni.'
      />
      <ScrollableStepContent inset>
        <Card className='min-h-64 w-full max-w-4xl rounded-md' contentExpandVertical>
          <div className='flex flex-col gap-4'>
            <span className='w-full text-lg font-bold capitalize'>Existing connections</span>
            <CardRule className='-mx-10' />
            <RadioGroupField
              name='connectionId'
              options={connectionOptions}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.SelectConnection,
                validator: isRequiredValidator(),
              })}
              onChange={handleConnectionChange}
              form={form}
            />
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit]}>
        {([canSubmit]) => (
          <StepButtons
            context={context}
            isSubmitting={getTablesMutation.isPending}
            nextBlocked={getTablesMutation.isPending}
            onNext={() => handleNext()}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
