import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import { useNavigate } from 'react-router'
import { LakehouseTable } from 'src/utils/lakehouse/types'

import { ButtonIconAction } from '../buttons/button-icon-action'
import { ProductColumnPolicies } from './product-column-policies'
import { ProductRowPolicies } from './product-row-policies'

export const ProductPolicies = ({
  product,
  canEdit,
  isLakehouseReady,
}: {
  product: LakehouseTable
  canEdit?: boolean
  isLakehouseReady?: boolean
}) => {
  const excludeAll = product.defaultColumnPolicyEffect === DataPolicyColumnEffect.Exclude
  const allowAll = product.defaultColumnPolicyEffect === DataPolicyColumnEffect.Allow

  const navigate = useNavigate()

  return (
    <div className='bg-uibg-0 p-6'>
      <div className='flex flex-col gap-2'>
        <div className='mb-4 flex items-center justify-between'>
          <h1 className='font-bold'>Data filtering rules</h1>
          <div>
            {/* One day maybe we can edit */}
            {/* {canEdit && (
              <ButtonIconAction
                icon='pencil'
                label='Update data products'
                onClick={() => navigate('../data/edit-tables')}
                disabled={!isLakehouseReady}
              />
            )} */}
          </div>
        </div>
        <div className='flex flex-col gap-4 text-sm'>
          {(allowAll || excludeAll) && (
            <div className='border-neutral-5 border bg-white p-4'>
              <strong>All columns</strong> are <strong>{allowAll ? 'enabled' : 'excluded'}</strong> by default
            </div>
          )}
          <div>
            <h2 className='p-2 font-semibold'>Column-level rules</h2>
            <ProductColumnPolicies product={product} />
          </div>
          <div>
            <h2 className='p-2 font-semibold'>Row-level rules</h2>
            <ProductRowPolicies product={product} />
          </div>
        </div>
      </div>
    </div>
  )
}
